import React from "react";
import PropTypes from "prop-types";

import logo from "../img/swengage-logo.svg";
import icon from "../img/swengage-icon.svg";

function Logo({ white = false, ...props }) {
    return (
        <img src={ (white ? icon : logo) } alt="SwEngage" {...props}/>
    )
}
Logo.propTypes = {
    white: PropTypes.bool
}

export default React.memo(Logo);
