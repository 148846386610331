import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/DetailOverviewRow";

function SurveyDetailOverview({ survey }) {
    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                        <DetailOverviewRow title="Naam">
                            { survey ? survey.name : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Beschrijving">
                            { survey ? (
                                survey.description
                            ) : <Skeleton count={ 3 }/> }
                        </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
SurveyDetailOverview.propTypes = {
    survey: PropTypes.object
};

export default React.memo(SurveyDetailOverview);
