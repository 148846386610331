import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    NavDropdown
} from "react-bootstrap";
import {
    Navbar
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import OrganizationsContext from "../../context/OrganizationsContext";

function BackofficeNavbar() {
    const userContext = useContext(AuthenticatedUserContext);
    const organizationsContext = useContext(OrganizationsContext);

    const user = userContext.user;

    return (
        <Navbar>
            { organizationsContext.organizations !== null && organizationsContext.organizations.length > 0 && (
                <NavDropdown title={ organizationsContext.currentOrganization ? organizationsContext.currentOrganization.name : "" } id="organization" align="right">
                    { organizationsContext.organizations.map((organization) => (
                        <Link
                            key={ organization.id }
                            to={ "/organization/" + organization.codeName + "/dashboard" }
                            className= { "dropdown-item" + (organizationsContext.currentOrganization && organization.id === organizationsContext.currentOrganization.id ? " active" : "")}
                        >
                            { organization.name }
                        </Link>
                    ))}
                    <NavDropdown.Divider />
                    <Link to={ "/organizations" } className="dropdown-item">
                        Alle organisaties
                    </Link>
                </NavDropdown>
            )}
            <NavDropdown title={ user && user.name } id="user">
                <NavDropdown.Item onClick={ userContext.logoutFunction }>
                    Uitloggen
                </NavDropdown.Item>
            </NavDropdown>
        </Navbar>
    );
}

export default React.memo(BackofficeNavbar);
