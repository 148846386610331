import React, {
    useMemo, useState
} from "react";
import PropTypes from "prop-types";
import {
    closestCenter,
    DndContext, DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";

import QuestionListItem from "./QuestionListItem";
import DraggableQuestionListItem from "./DraggableQuestionListItem";

function QuestionsList({ questions, setQuestions, survey }) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [draggingQuestion, setDraggingQuestion] = useState(null);

    const onDragStart = useMemo(() => {
        return (event) => {
            const { active } = event;
            const question = questions.find((searchQuestion) => searchQuestion.id === active.id);
            setDraggingQuestion(question);
        }
    }, [])
    const onDragEnd = useMemo(() => {
        return (event) => {
            const { active, over } = event;

            if(active.id !== over.id) {
                setQuestions((oldQuestions) => {
                    const oldIndex = oldQuestions.findIndex((searchQuestion) => searchQuestion.id === active.id);
                    const newIndex = oldQuestions.findIndex((searchQuestion) => searchQuestion.id === over.id);

                    return arrayMove(oldQuestions, oldIndex, newIndex);
                });
            }

            setDraggingQuestion(null);
        }
    }, [])

    return (
        <DndContext
            sensors={ sensors }
            collisionDetection={ closestCenter }
            onDragStart={ onDragStart }
            onDragEnd={ onDragEnd }
        >
            <SortableContext
                items={ questions }
                strategy={ verticalListSortingStrategy }
            >
                { questions.map((question) => (
                    <DraggableQuestionListItem
                        key={ question.id }
                        question={ question }
                        survey={ survey }
                    />
                ))}
                <DragOverlay>
                    { draggingQuestion ? (
                        <QuestionListItem
                            question={ draggingQuestion }
                            survey={ survey }
                        />
                    ) : null }
                </DragOverlay>
            </SortableContext>
        </DndContext>
    )
}
QuestionsList.propTypes = {
    questions: PropTypes.array,
    setQuestions: PropTypes.func,
    survey: PropTypes.object
}

export default React.memo(QuestionsList);
