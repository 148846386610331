import React from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";
import PropTypes from "prop-types";

function Helmet({ title = null }) {
    return (
        <ReactHelmet>
            <title>
                { title === null ? "SwEngage Backoffice" : (title + " - SwEngage Backoffice") }
            </title>
        </ReactHelmet>
    );
}
Helmet.propTypes = {
    title: PropTypes.string
}

export default React.memo(Helmet);
