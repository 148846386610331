import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";

import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../../context/OrganizationsContext";
import Loading from "../../../components/Loading";

class ProjectDetailSurveys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            surveys: null,
            error: null
        }
    }

    componentDidMount() {
        this.getSurveys();
    }

    getSurveys() {
        const projectId = this.props.projectId;
        axios.post("/getSurveysForProject", { projectId, organizationId: this.props.organizationsContext.currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ surveys: response.data.surveys });
                } else {
                    this.setState({ error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                this.setState({ error: "Er was een fout bij het ophalen van de data." });
            });
    }

    render() {
        const currentOrganization = this.props.organizationsContext.currentOrganization;
        const { surveys, error } = this.state;
        if(error) {
            return (
                <Alert variant="danger">{ error }</Alert>
            )
        }
        if(!surveys) {
            return (
                <Loading/>
            )
        }
        if(surveys.length === 0) {
            return (
                <div className="text-center">
                    <h1><i className="fas fa-box-open"/></h1>
                    <h3>Geen surveys</h3>
                    <p>Er zijn geen surveys in dit project.</p>
                </div>
            )
        }
        return (
            <Table hover>
                <thead className="thead-light">
                    <tr className="tr-sticky">
                        <th className="text-center">#</th>
                        <th>Naam</th>
                    </tr>
                </thead>
                <tbody>
                    { surveys.map((survey) => {
                        const url = `/organization/${currentOrganization.codeName}/survey/${survey.id}`;
                        return (
                            <tr className="table-clickable-row" key={ survey.id }>
                                <td className="text-center">
                                    <Link to={ url }>
                                        { survey.id }
                                    </Link>
                                </td>
                                <td>
                                    <Link to={ url }>
                                        { survey.name }
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }
}

ProjectDetailSurveys.propTypes = {
    project: PropTypes.object,
    projectId: PropTypes.number,
    organizationsContext: OrganizationsContextPropTypes
};

export default React.memo(withOrganizationsContext(ProjectDetailSurveys));
