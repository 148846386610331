import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";

import Title from "../../components/Title";
import Loading from "../../components/Loading";
import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../context/OrganizationsContext";
import Helmet from "../../components/Helmet";

class Surveys extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            surveys: null,
            error: null
        }
    }

    componentDidMount() {
        this.getSurveys();
    }

    getSurveys() {
        const currentOrganization = this.props.organizationsContext.currentOrganization
        axios.post("/getSurveys", { organizationId: currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ surveys: response.data.surveys });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data." });
            })
    }

    render() {
        const currentOrganization = this.props.organizationsContext.currentOrganization
        return (
            <React.Fragment>
                <Helmet title="Surveys"/>
                <Title pretitle="Overzicht">
                    Surveys
                </Title>
                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : this.state.surveys === null ? (
                    <Loading/>
                ) : this.state.surveys.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-box-open"/></h1>
                        <h3>Geen surveys</h3>
                        <p>Er zijn geen surveys in deze organisatie.</p>
                    </div>
                ) : (
                    <Table hover>
                        <thead className="thead-light">
                            <tr className="tr-sticky">
                                <th className="text-center">#</th>
                                <th>Naam</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.surveys.map((survey) => {
                                const url = `/organization/${currentOrganization.codeName}/survey/${survey.id}`;
                                return (
                                    <tr className="table-clickable-row" key={ survey.id }>
                                        <td className="text-center">
                                            <Link to={ url }>
                                                { survey.id }
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={ url }>
                                                { survey.name }
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                )}
            </React.Fragment>
        )
    }

}
Surveys.propTypes = {
    organizationsContext: OrganizationsContextPropTypes
}

export default withOrganizationsContext(Surveys);
