import React from "react";
import PropTypes from "prop-types";

function Title(props) {
    return (
        <div className={ "page-title-container" + ( props.noBottom ? "page-title-container-no-bottom" : "" )}>
            { props.preChildren }
            <h6 className="page-pretitle">{ props.pretitle }</h6>
            <h1 className="page-title">
                { props.children }
            </h1>
        </div>
    );
}
Title.propTypes = {
    preChildren: PropTypes.node,
    pretitle: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ]),
    noBottom: PropTypes.bool
}

export default React.memo(Title);
