import React from "react";
import {
    Redirect,
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Logo from "../components/Logo";
import Loading from "../components/Loading";
import AuthenticatedUserContext from "../context/AuthenticatedUserContext";
import OrganizationsContext from "../context/OrganizationsContext";
import Helmet from "../components/Helmet";

class Organizations extends React.Component {
    componentDidMount() {
        this.context.refreshOrganizations();
    }

    render() {
        return (
            <div className="container-fluid login-background">
                <Helmet title="Organisaties"/>
                <div className="login-column">
                    <Logo className="login-logo"/>
                    <h3 className="mt-0 text-center text-muted mb-4">Organisaties</h3>
                    <AuthenticatedUserContext.Consumer>
                        {value =>
                            <div className="text-center mb-3">
                                <p className="mb-0">
                                    Welkom { value.user.name }
                                </p>
                                <button onClick={ value.logoutFunction } className="btn btn-link btn-sm">
                                    Uitloggen
                                </button>
                            </div>
                        }
                    </AuthenticatedUserContext.Consumer>
                    { this.context.error ? (
                        <Alert variant="danger">{ this.context.error }</Alert>
                    ) : this.context.organizations === null ? (
                        <Loading/>
                    ) : (
                        <div className="list-group">
                            { this.context.organizations.length === 1 && (
                                <Redirect to={ "/organization/" + this.context.organizations[0].codeName + "/dashboard" }/>
                            )}
                            { this.context.organizations.map((organization) => (
                                <Link to={ "/organization/" + organization.codeName + "/dashboard" } key={ organization.id } className="list-group-item list-group-item-action">
                                    { organization.name }
                                </Link>
                            ))}
                        </div>
                    )}
                </div>
                <div className="login-footer">
                    Copyright &copy; { new Date().getFullYear() } SR Productions. All rights reserved.
                </div>
            </div>
        );
    }
}
Organizations.contextType = OrganizationsContext;

export default Organizations;
