import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../../../context/OrganizationsContext";

class NewSurveyInviteModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,

            inviteToken: null
        }
        this.onShow = this.onShow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onShow() {
        this.setState({ loading: false, error: null });
    }

    onSubmit() {
        const surveyId = this.props.survey.id;
        this.setState({ loading: true, error: null });
        axios.post("/addSurveyInvite", { surveyId, organizationId: this.props.organizationsContext.currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ inviteToken: response.data.inviteToken });
                    this.props.onInviteAdded();
                } else {
                    this.setState({ error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het ophalen van de data." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const { loading } = this.state;
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow } size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nieuwe uitnodiging
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}
                    { this.state.inviteToken ? (
                        <a
                            href={ "https://app.swengage.com/survey/" + this.state.inviteToken }
                            className="btn btn-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Open link
                        </a>
                    ) : (
                        <p className="mb-0">Klik op opslaan om een uitnodiging aan te maken.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    { this.state.inviteToken ? (
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ loading }>
                            Sluiten
                        </Button>
                    ) : (
                        <React.Fragment>
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ loading }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.onSubmit } disabled={ loading }>
                                { loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                )}
                                Opslaan
                            </Button>
                        </React.Fragment>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}
NewSurveyInviteModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onInviteAdded: PropTypes.func.isRequired,
    survey: PropTypes.object.isRequired,
    organizationsContext: OrganizationsContextPropTypes
}

export default withOrganizationsContext(NewSurveyInviteModal);
