import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem
} from "@zandor300/backoffice-framework";
import * as ReactRouterPropTypes from "react-router-prop-types";

import OrganizationsContext from "../../../context/OrganizationsContext";
import Error404 from "../../error/Error404";
import Title from "../../../components/Title";
import Helmet from "../../../components/Helmet";

import ProjectDetailOverview from "./ProjectDetailOverview";
import ProjectDetailSurveys from "./ProjectDetailSurveys";

function ProjectDetail(props) {
    const organizationsContext = useContext(OrganizationsContext);
    const projectId = props.match.params.projectId;
    const urlPrefix = `/organization/${organizationsContext.currentOrganization.codeName}/project/${projectId}`;

    const [project, setProject] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshProject = useCallback(() => {
        setProject(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getProject", { projectId, organizationId: organizationsContext.currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    setProject(response.data.project);
                } else {
                    setProject(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setProject(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [projectId, organizationsContext.currentOrganization]);

    useEffect(() => {
        if(isNaN(parseInt(projectId, 10))) {
            return;
        }
        refreshProject();
    }, [projectId, refreshProject]);

    const childProps = {
        projectId,
        project,
        error,
        setProject,
        refreshProject
    };

    if(isNaN(parseInt(projectId, 10)) || projectId < 1 || errorCode === "DOESNT_EXIST") {
        return <Error404/>
    }

    return (
        <React.Fragment>
            <Helmet title={ "Project #" + projectId + (project ? ` - ${project.name}` : "") }/>

            <Title pretitle="Detail" noBottom={ error === null }>
                Project <small className="text-muted">#{projectId}</small>
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={ urlPrefix }>
                            Overzicht
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/surveys" }>
                            Surveys
                        </TabBarItem>
                    </TabBar>

                    <Switch>
                        <Route path={ urlPrefix } exact>
                            <ProjectDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/surveys" } exact>
                            <ProjectDetailSurveys { ...childProps }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: urlPrefix,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
ProjectDetail.propTypes = {
    match: ReactRouterPropTypes.match.isRequired
}

export default React.memo(ProjectDetail);
