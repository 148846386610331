import React, {
    createContext
} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    matchPath,
    withRouter
} from "react-router-dom";

const OrganizationsContext = createContext(null);

class OrganizationsManagerInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            context: {
                organizations: null,
                currentOrganization: undefined,
                error: null,
                refreshOrganizations: this.getOrganizations.bind(this)
            }
        }
    }

    componentDidMount() {
        this.getOrganizations();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.pathname !== prevProps.location.pathname || this.state.context.organizations !== prevState.context.organizations) {
            this.updateCurrentOrganization();
        }
    }

    updateCurrentOrganization() {
        console.log("updateCurrentOrganization");
        if(!this.state.context.organizations) {
            return;
        }
        const match = matchPath(this.props.location.pathname, {
            path: "/organization/:organizationCodeName"
        });
        if(match === null) {
            this.setContextState({ currentOrganization: undefined });
            return;
        }
        const currentCodeName = match.params.organizationCodeName;
        if(!this.state.context.currentOrganization || this.state.context.currentOrganization.codeName !== currentCodeName) {
            const currentOrganization = this.state.context.organizations.find((organization) => organization.codeName === currentCodeName);
            this.setContextState({ currentOrganization: currentOrganization === undefined ? null : currentOrganization });
        }
    }

    setContextState(state) {
        this.setState({ context: { ...this.state.context, ...state }});
    }

    getOrganizations() {
        this.setContextState({ organizations: null, error: null });
        axios.get("/getOrganizations")
            .then((response) => {
                if(response.data.valid) {
                    const organizations = response.data.organizations.sort((organization1, organization2) => {
                        if(organization1.name < organization2.name) return -1;
                        if(organization1.name > organization2.name) return 1;
                        return 0;
                    });
                    this.setContextState({ organizations });
                } else {
                    this.setContextState({ error: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.setContextState({ error: "Er ging iets fout bij het ophalen van de data." });
            })
    }

    render() {
        return (
            <OrganizationsContext.Provider value={ this.state.context }>
                { this.props.children }
            </OrganizationsContext.Provider>
        )
    }
}
OrganizationsManagerInternal.propTypes = {
    children: PropTypes.element,
    location: PropTypes.object
}
export const OrganizationsManager = withRouter(OrganizationsManagerInternal);

export function withOrganizationsContext(Component) {
    return function contextComponent(props) {
        return (
            <OrganizationsContext.Consumer>
                {context => <Component {...props} organizationsContext={context} />}
            </OrganizationsContext.Consumer>
        )
    }
}

export const OrganizationsContextPropTypes = PropTypes.shape({
    organizations: PropTypes.array,
    currentOrganization: PropTypes.object,
    error: PropTypes.string,
    refreshOrganizations: PropTypes.func.isRequired
});

export default OrganizationsContext;
