import React from "react";

import Title from "./../../components/Title";
import Helmet from "../../components/Helmet";

export class Dashboard extends React.Component {
    componentDidMount() {
        document.title = "Dashboard - SwEngage";
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="Dashboard"/>
                <Title pretitle="Overzicht">
                    Dashboard
                </Title>
            </React.Fragment>
        )
    }
}

export default Dashboard;
