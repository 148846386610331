import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../../components/DetailOverviewRow";

function SurveyQuestionDetailOverview({ surveyQuestion }) {
    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                        <DetailOverviewRow title="Titel">
                            { surveyQuestion ? surveyQuestion.title : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Beschrijving">
                            { surveyQuestion ? (
                                surveyQuestion.description
                            ) : <Skeleton count={ 3 }/> }
                        </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
SurveyQuestionDetailOverview.propTypes = {
    surveyQuestion: PropTypes.object
};

export default React.memo(SurveyQuestionDetailOverview);
