import React from "react";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";

import Logo from "../components/Logo";
import Helmet from "../components/Helmet";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            error: null,
            loading: false
        };
    }

    onErrorOccurred(errorMessage) {
        this.setState({ error: errorMessage, loading: false });
    }

    render() {
        return (
            <AuthenticatedUserContext.Consumer>
                { value =>
                    <div className="container-fluid login-background">
                        <Helmet title="Login"/>
                        <div className="login-column" style={{ paddingRight: "2rem", paddingLeft: "2rem" }}>
                            <div className="d-flex justify-content-center">
                                <Logo className="login-logo"/>
                            </div>
                            <h3 className="mt-0 text-center text-muted mb-4">Login</h3>
                            { this.state.error !== null && (
                                <div className="alert alert-danger">{ this.state.error }</div>
                            )}
                            <form id="login-form" onSubmit={ (event) => {
                                event.preventDefault();
                                this.setState({ error: null, loading: true });
                                value.loginFunction(this.state, this.onErrorOccurred.bind(this));
                            } }>
                                <div className="form-group">
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email" disabled={ this.state.loading }
                                           value={this.state.email} onChange={(event) => this.setState({ email: event.target.value })}/>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" id="password" name="password" placeholder="Wachtwoord" disabled={ this.state.loading }
                                           value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })}/>
                                </div>
                                <button type="submit" className="btn btn-primary float-right">Inloggen</button>
                            </form>
                        </div>
                        <div className="login-footer">
                            Copyright &copy; { new Date().getFullYear() } SR Productions. All rights reserved.
                        </div>
                    </div>
                }
            </AuthenticatedUserContext.Consumer>
        );
    }
}

export default Login;
