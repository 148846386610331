import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem
} from "@zandor300/backoffice-framework";
import * as ReactRouterPropTypes from "react-router-prop-types";
import Skeleton from "react-loading-skeleton";

import OrganizationsContext from "../../../context/OrganizationsContext";
import Error404 from "../../error/Error404";
import Title from "../../../components/Title";
import Helmet from "../../../components/Helmet";

import SurveyDetailOverview from "./SurveyDetailOverview";
import SurveyDetailQuestions from "./SurveyDetailQuestions";
import SurveyDetailInvites from "./SurveyDetailInvites";

function SurveyDetail(props) {
    const organizationsContext = useContext(OrganizationsContext);
    const surveyId = parseInt(props.match.params.surveyId, 10);
    const urlPrefix = `/organization/${organizationsContext.currentOrganization.codeName}/survey/${surveyId}`;

    const [survey, setSurvey] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshSurvey = useCallback(() => {
        setSurvey(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getSurvey", { surveyId, organizationId: organizationsContext.currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    setSurvey(response.data.survey);
                } else {
                    setSurvey(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setSurvey(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [surveyId, organizationsContext.currentOrganization]);

    useEffect(() => {
        if(isNaN(parseInt(surveyId, 10))) {
            return;
        }
        refreshSurvey();
    }, [surveyId, refreshSurvey]);

    const childProps = {
        surveyId,
        survey,
        error,
        setSurvey,
        refreshSurvey
    };

    if(isNaN(parseInt(surveyId, 10)) || surveyId < 1 || errorCode === "DOESNT_EXIST") {
        return <Error404/>
    }

    return (
        <React.Fragment>
            <Helmet title={ "Survey #" + surveyId + (survey ? ` - ${survey.name}` : "") }/>

            <Title pretitle="Detail" noBottom={ error === null }>
                { survey ? (
                    <React.Fragment>
                        { survey.name }
                        {" "}<small className="text-muted">#{surveyId}</small>
                    </React.Fragment>
                ) : (
                    <Skeleton width={ 300 }/>
                )}
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={ urlPrefix }>
                            Overzicht
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/questions" }>
                            Vragen
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/invites" }>
                            Uitnodigingen
                        </TabBarItem>
                    </TabBar>

                    <Switch>
                        <Route path={ urlPrefix } exact>
                            <SurveyDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/questions" } exact>
                            <SurveyDetailQuestions { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/invites" } exact>
                            <SurveyDetailInvites { ...childProps }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: urlPrefix,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
SurveyDetail.propTypes = {
    match: ReactRouterPropTypes.match.isRequired
}

export default React.memo(SurveyDetail);
