import React from "react";
import {
    Table
} from "react-bootstrap";
import PropTypes from "prop-types";

function SurveyQuestionAnswersTable({ answers }) {
    return (
        <Table>
            <thead>
                <tr>
                    <th>
                        #
                    </th>
                    <th>
                        Antwoord
                    </th>
                    <th>
                        Opmerkingen
                    </th>
                </tr>
            </thead>
            <tbody>
                { answers.map((answer) => (
                    <tr key={ answer.id }>
                        <th>{ answer.id }</th>
                        <td>{ answer.response }</td>
                        <td>{ answer.message }</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}
SurveyQuestionAnswersTable.propTypes = {
    answers: PropTypes.array
}

export default React.memo(SurveyQuestionAnswersTable);
