import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Alert,
    Button
} from "react-bootstrap";

import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../../context/OrganizationsContext";
import Loading from "../../../components/Loading";
import NewSurveyQuestionModal from "./modal/NewSurveyQuestionModal";
import QuestionsList from "./components/QuestionsList";

class SurveyDetailQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: null,
            error: null,
            success: null,
            changedOrder: false,
            savingOrder: false,

            showNewQuestionModal: false
        }
        this.setQuestions = this.setQuestions.bind(this);
    }

    componentDidMount() {
        if(this.props.survey) {
            this.getQuestions();
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if(this.props.survey && this.props.survey !== prevProps.survey) {
            this.getQuestions();
        }
    }

    getQuestions() {
        const surveyId = this.props.survey.id;
        this.setState({ error: null });
        axios.post("/getSurveyQuestions", { surveyId, organizationId: this.props.organizationsContext.currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ questions: response.data.surveyQuestions });
                } else {
                    this.setState({ error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                this.setState({ error: "Er was een fout bij het ophalen van de data." });
            });
    }

    setQuestions(func) {
        this.setState(({ questions }) => {
            return { questions: func(questions) }
        });
    }

    render() {
        const survey = this.props.survey;
        const { questions, error } = this.state;
        if(error) {
            return (
                <Alert variant="danger">{ error }</Alert>
            )
        }
        if(!survey || !questions) {
            return (
                <Loading/>
            )
        }
        if(questions.length === 0) {
            return (
                <div className="text-center">
                    <h1><i className="fas fa-box-open"/></h1>
                    <h3>Geen vragen</h3>
                    <p>Er zijn geen vragen voor deze survey.</p>
                </div>
            )
        }
        return (
            <React.Fragment>
                <NewSurveyQuestionModal
                    show={ this.state.showNewQuestionModal }
                    handleClose={ () => this.setState({ showNewQuestionModal: false }) }
                    survey={ survey }
                />
                <Button variant="primary" className="mb-3" onClick={ () => this.setState({ showNewQuestionModal: true }) }>
                    <i className="fas fa-plus mr-2"/>
                    Nieuwe vraag
                </Button>
                <QuestionsList
                    questions={ questions }
                    setQuestions={ this.setQuestions }
                    survey={ survey }
                />
            </React.Fragment>
        )
    }
}

SurveyDetailQuestions.propTypes = {
    survey: PropTypes.object,
    surveyId: PropTypes.number,
    organizationsContext: OrganizationsContextPropTypes
};

export default React.memo(withOrganizationsContext(SurveyDetailQuestions));
