import React from "react";
import PropTypes from "prop-types";
import {
    useSortable
} from "@dnd-kit/sortable";
import {
    CSS
} from "@dnd-kit/utilities";

import QuestionListItem from "./QuestionListItem";

function DraggableQuestionListItem({ question, survey }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: question.id
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0
    };

    return (
        <QuestionListItem
            question={ question }
            survey={ survey }
            setNodeRef={ setNodeRef }
            style={ style }
            listeners={ listeners }
            attributes={ attributes }
            isDragging={ isDragging }
        />
    );
}
DraggableQuestionListItem.propTypes = {
    question: PropTypes.object,
    survey: PropTypes.object
}

export default React.memo(DraggableQuestionListItem);
