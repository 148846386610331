import React from "react";
import {
    withRouter
} from "react-router-dom";
import {
    Sidebar,
    SidebarLink
} from "@zandor300/backoffice-framework";

import Logo from "../Logo";
import ReactRouterPropTypes from "react-router-prop-types";

function BackofficeSidebar({ match }) {
    return (
        <Sidebar
            logo={
                <Logo white/>
            }
        >
            <SidebarLink
                to={ `${match.url}/dashboard` }
                icon="fas fa-tachometer-alt"
            >
                Dashboard
            </SidebarLink>
            <SidebarLink
                to={ `${match.url}/projects` }
                additionalUrls={[`${match.url}/project/:projectId`]}
                icon="fas fa-project-diagram"
            >
                Projecten
            </SidebarLink>
            <SidebarLink
                to={ `${match.url}/surveys` }
                additionalUrls={[`${match.url}/survey/:surveyId`]}
                icon="fas fa-poll"
            >
                Surveys
            </SidebarLink>
        </Sidebar>
    );
}
BackofficeSidebar.propTypes = {
    match: ReactRouterPropTypes.match
};

export default withRouter(React.memo(BackofficeSidebar));
