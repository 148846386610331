import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import PropTypes from "prop-types";

import Loading from "../../../../components/Loading";
import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../../../context/OrganizationsContext";
import SurveyQuestionAnswersTable from "./components/SurveyQuestionAnswersTable";
import SurveyQuestionAnswersResponseChart from "./components/SurveyQuestionAnswersResponseChart";
import ChartCard from "../../../../components/chart/ChartCard";

class SurveyQuestionDetailAnswers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: null,
            error: null
        }
    }

    componentDidMount() {
        if(this.props.surveyQuestion) {
            this.getAnswers();
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.surveyQuestion) {
            if(!prevProps.surveyQuestion || (prevProps.surveyQuestion && prevProps.surveyQuestion.id !== this.props.surveyQuestion.id)) {
                this.getAnswers();
            }
        }
    }

    getAnswers() {
        this.setState({ answers: null, error: null });
        axios.post("/getSurveyQuestionAnswers", {
            organizationId: this.props.organizationsContext.currentOrganization.id,
            surveyQuestionId: this.props.surveyQuestion.id
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ answers: response.data.surveyQuestionAnswers });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(!this.state.answers) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                { this.state.answers.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-image"/></h1>
                        <h4>Geen antwoorden</h4>
                        <p>Deze vraag heeft nog geen antwoorden.</p>
                    </div>
                ) : (
                    <React.Fragment>
                        <ChartCard title="Antwoorden" height={ 300 }>
                            <SurveyQuestionAnswersResponseChart
                                answers={ this.state.answers }
                            />
                        </ChartCard>
                        <SurveyQuestionAnswersTable
                            answers={ this.state.answers }
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
SurveyQuestionDetailAnswers.propTypes = {
    surveyQuestion: PropTypes.object,
    setSurveyQuestion: PropTypes.func.isRequired,
    refreshSurveyQuestion: PropTypes.func.isRequired,
    organizationsContext: OrganizationsContextPropTypes
}

export default withOrganizationsContext(SurveyQuestionDetailAnswers);
