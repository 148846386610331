import React, {
    useState
} from "react";
import {
    Button
} from "react-bootstrap";

import ChartModal from "./ChartModal";
import PropTypes from "prop-types";

function ChartCard({ title, children, height = 500 }) {
    const [showModal, setShowModal] = useState(false);
    return (
        <React.Fragment>
            <ChartModal
                title={ title }
                show={ showModal }
                handleClose={ () => setShowModal(false) }
            >
                { children }
            </ChartModal>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="card-title">{ title }</h5>
                        </div>
                        <div className="ml-2">
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={ () => setShowModal(true) }
                            >
                                <i className="fas fa-expand fa-fw"/>
                            </Button>
                        </div>
                    </div>
                    <div className="w-100" style={{ height: `${height}px` }}>
                        { children }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
ChartCard.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element
    ]),
    height: PropTypes.number.isRequired
}

export default React.memo(ChartCard);
