import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import {
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../../../context/OrganizationsContext";
import SurveyQuestionForm from "../../survey-questions/components/SurveyQuestionForm";

class NewSurveyQuestionModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,

            title: "",
            description: "",
        }
        this.onShow = this.onShow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setSurveyQuestionState = this.setSurveyQuestionState.bind(this);
    }

    onShow() {
        this.setState({ loading: false, error: null, title: "", description: "" });
    }

    onSubmit() {
        if(this.state.title.trim().length === 0) {
            this.setState({ error: "Naam is vereist." });
            return;
        }
        this.setState({ error: null, loading: true });
        const currentOrganization = this.props.organizationsContext.currentOrganization;
        const survey = this.props.survey;
        axios.post("/addSurveyQuestion", {
            organizationId: currentOrganization.id,
            surveyId: survey.id,
            title: this.state.title,
            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    const surveyQuestion = response.data.surveyQuestion;
                    this.props.history.push("/organization/" + currentOrganization.codeName + "/survey/" + survey.id + "/question/" + surveyQuestion.id);
                } else {
                    this.setState({ loading: false, error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ loading: false, error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setSurveyQuestionState(state) {
        this.setState({ ...state });
    }

    render() {
        const { loading } = this.state;
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow } size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Nieuwe vraag
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}
                    <SurveyQuestionForm
                        surveyQuestion={ this.state }
                        setSurveyQuestion={ this.setSurveyQuestionState }
                        loading={ this.state.loading }
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose } disabled={ loading }>
                        Annuleer
                    </Button>
                    <Button variant="primary" onClick={ this.onSubmit } disabled={ loading }>
                        { loading && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        Aanmaken
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
NewSurveyQuestionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    survey: PropTypes.object.isRequired,
    organizationsContext: OrganizationsContextPropTypes,
    history: ReactRouterPropTypes.history.isRequired
}

export default withOrganizationsContext(withRouter(NewSurveyQuestionModal));
