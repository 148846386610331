import React from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";
import {
    arrayMove,
    sortableContainer,
    sortableElement
} from "react-sortable-hoc";

import Loading from "../../../../components/Loading";
import DateFormatter from "../../../../components/DateFormatter";
import UploadSurveyQuestionImageModal from "./modal/UploadSurveyQuestionImageModal";
import {OrganizationsContextPropTypes, withOrganizationsContext} from "../../../../context/OrganizationsContext";

const SortableItem = sortableElement(({children}) => (
    <div>{children}</div>
));

const SortableContainer = sortableContainer(({children}) => (
    <div>{children}</div>
));

class SurveyQuestionDetailImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            disabledImages: null,
            error: null,
            success: null,
            savingError: null,
            changedOrder: false,
            savingOrder: false,

            showUploadImageModal: false
        }
    }

    componentDidMount() {
        if(this.props.surveyQuestion) {
            this.setImages(this.props.surveyQuestion);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.surveyQuestion !== this.props.surveyQuestion) {
            this.setImages(this.props.surveyQuestion);
        }
    }

    setImages(surveyQuestion) {
        if(!surveyQuestion) {
            this.setState({ images: null, disabledImages: null });
            return;
        }
        let images = surveyQuestion.images;
        let enabledImages = images.filter((image) => {
            return image.enabled;
        });
        let disabledImages = images.filter((image) => {
            return !image.enabled;
        });
        this.setState({
            images: enabledImages,
            disabledImages
        });
    }

    setImageEnabled(imageId, enabled) {
        this.setState({ savingError: null, success: null });
        axios.post("/setSurveyQuestionImageEnabled", {
            organizationId: this.props.organizationsContext.currentOrganization.id,
            surveyQuestionId: this.props.surveyQuestion.id,
            imageId,
            state: enabled ? 1 : 0
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setImages(response.data.surveyQuestion);
                    this.props.setSurveyQuestion(response.data.surveyQuestion);
                    this.setState({ success: "Foto " + (enabled ? "ingeschakeld" : "uitgeschakeld") + "." });
                } else {
                    this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setImagesOrder() {
        this.setState({ savingOrder: true, savingError: null, success: null });
        const imageOrder = this.state.images.map((image) => image.id).join(",");
        axios.post("/setSurveyQuestionImagesOrder", {
            organizationId: this.props.organizationsContext.currentOrganization.id,
            surveyQuestionId: this.props.surveyQuestion.id,
            imageOrder
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setImages(response.data.surveyQuestion);
                    this.props.setSurveyQuestion(response.data.surveyQuestion);
                    this.setState({ changedOrder: false, success: "Foto volgorde opgeslagen." });
                } else {
                    this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ savingOrder: false });
            });
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState(({images}) => {
            return { images: arrayMove(images, oldIndex, newIndex), changedOrder: true }
        });
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(!this.state.images) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                <UploadSurveyQuestionImageModal
                    show={ this.state.showUploadImageModal }
                    handleClose={ () => { this.setState({ showUploadImageModal: false }) } }
                    surveyQuestion={ this.props.surveyQuestion }
                    imageUploaded={ this.props.refreshSurveyQuestion }
                />

                { this.state.images.length === 0 && this.state.disabledImages.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-image"/></h1>
                        <h4>Geen foto&apos;s</h4>
                        <p>Deze vraag heeft nog geen foto&apos;s.</p>
                        <Button variant="primary" onClick={ () => { this.setState({ showUploadImageModal: true }) } }>
                            <i className="fas fa-plus mr-2"/>
                            Eerste foto uploaden
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        { this.state.success && (
                            <Alert variant="success">{ this.state.success }</Alert>
                        )}
                        { this.state.savingError && (
                            <Alert variant="danger">{ this.state.savingError }</Alert>
                        )}
                        <div>
                            <Button variant="primary" className="mb-2 mr-2" onClick={ this.setImagesOrder.bind(this) } disabled={ this.state.savingOrder || !this.state.changedOrder }>
                                <i className="fas fa-save mr-2"/>
                                Volgorde opslaan
                            </Button>
                            <Button variant="secondary" className="mb-2 mr-2" onClick={ () => { this.setState({ showUploadImageModal: true }) } } disabled={ this.state.savingOrder }>
                                <i className="fas fa-plus mr-2"/>
                                Foto uploaden
                            </Button>
                        </div>
                        { this.state.images.length === 0 ? (
                            <p className="mt-3">Geen ingeschakelde afbeeldingen.</p>
                        ) : (
                            <SortableContainer onSortEnd={ this.onSortEnd.bind(this) }>
                                { this.state.images.map((image, index) => (
                                    <SortableItem key={ image.id } index={ index } disabled={ this.state.savingOrder }>
                                        <div className="card mb-2" style={{ cursor: "grab" }}>
                                            <div className="card-body">
                                                <Button variant="secondary" size="sm" className="float-right" onClick={ this.setImageEnabled.bind(this, image.id, false) }>
                                                    <i className="fas fa-minus mr-2"/>
                                                    Foto uitschakelen
                                                </Button>
                                                <img src={ image.url } height="125px" key={ image.id } alt={ image.id } className="float-left mr-3"/>
                                                <div>{ image.author.name }</div>
                                                <div><DateFormatter date={ image.uploadDate }/></div>
                                            </div>
                                        </div>
                                    </SortableItem>
                                ))}
                            </SortableContainer>
                        )}
                        { this.state.disabledImages.length > 0 && (
                            <React.Fragment>
                                <h4 className="mt-3">Uitgeschakelde foto&apos;s</h4>
                                { this.state.disabledImages.map((image, index) => (
                                    <div className="card mb-2" key={ index }>
                                        <div className="card-body">
                                            <Button variant="secondary" size="sm" className="float-right" onClick={ this.setImageEnabled.bind(this, image.id, true) }>
                                                <i className="fas fa-plus mr-2"/>
                                                Foto inschakelen
                                            </Button>
                                            <img src={ image.url } height="125px" key={ image.id } alt={ image.id } className="float-left mr-3" style={{ opacity: 0.5 }}/>
                                            <div>{ image.author.name }</div>
                                            <div><DateFormatter date={ image.uploadDate }/></div>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
SurveyQuestionDetailImages.propTypes = {
    surveyQuestion: PropTypes.object,
    setSurveyQuestion: PropTypes.func.isRequired,
    refreshSurveyQuestion: PropTypes.func.isRequired,
    organizationsContext: OrganizationsContextPropTypes
}

export default withOrganizationsContext(SurveyQuestionDetailImages);
