import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem
} from "@zandor300/backoffice-framework";
import * as ReactRouterPropTypes from "react-router-prop-types";

import OrganizationsContext from "../../../../context/OrganizationsContext";
import Error404 from "../../../error/Error404";
import Title from "../../../../components/Title";
import Helmet from "../../../../components/Helmet";

import SurveyQuestionDetailOverview from "./SurveyQuestionDetailOverview";
import SurveyQuestionDetailImages from "./SurveyQuestionDetailImages";
import SurveyQuestionDetailAnswers from "./SurveyQuestionDetailAnswers";
import SurveyQuestionDetailEdit from "./SurveyQuestionDetailEdit";

function SurveyQuestionDetail({ match }) {
    const organizationsContext = useContext(OrganizationsContext);
    const surveyId = parseInt(match.params.surveyId, 10);
    const surveyQuestionId = parseInt(match.params.surveyQuestionId, 10);
    const urlPrefix = `/organization/${organizationsContext.currentOrganization.codeName}/survey/${surveyId}/question/${surveyQuestionId}`;

    const [surveyQuestion, setSurveyQuestion] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshSurveyQuestion = useCallback(() => {
        setSurveyQuestion(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getSurveyQuestion", { surveyQuestionId, organizationId: organizationsContext.currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    setSurveyQuestion(response.data.surveyQuestion);
                } else {
                    setSurveyQuestion(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setSurveyQuestion(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [surveyQuestionId, organizationsContext.currentOrganization]);

    useEffect(() => {
        if(isNaN(parseInt(surveyQuestionId, 10))) {
            return;
        }
        refreshSurveyQuestion();
    }, [surveyQuestionId, refreshSurveyQuestion]);

    const childProps = {
        surveyQuestionId,
        surveyQuestion,
        error,
        setSurveyQuestion,
        refreshSurveyQuestion
    };

    if(isNaN(parseInt(surveyQuestionId, 10)) || surveyQuestionId < 1 || errorCode === "DOESNT_EXIST") {
        return <Error404/>
    }

    return (
        <React.Fragment>
            <Helmet title={ "Enquete vraag #" + surveyQuestionId + (surveyQuestion ? ` - ${surveyQuestion.title}` : "") }/>

            <Title pretitle="Detail" noBottom={ error === null }>
                Survey Vraag <small className="text-muted">#{surveyQuestionId}</small>
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={ urlPrefix }>
                            Overzicht
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/images" }>
                            Foto&apos;s
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/answers" }>
                            Antwoorden
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/edit" }>
                            Bewerken
                        </TabBarItem>
                    </TabBar>

                    <Switch>
                        <Route path={ urlPrefix } exact>
                            <SurveyQuestionDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/images" } exact>
                            <SurveyQuestionDetailImages { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/answers" } exact>
                            <SurveyQuestionDetailAnswers { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/edit" } exact>
                            <SurveyQuestionDetailEdit { ...childProps }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: urlPrefix,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
SurveyQuestionDetail.propTypes = {
    match: ReactRouterPropTypes.match.isRequired
}

export default React.memo(SurveyQuestionDetail);
