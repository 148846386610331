import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../../../context/OrganizationsContext";
import Loading from "../../../../components/Loading";
import SurveyQuestionForm from "../components/SurveyQuestionForm";

class SurveyQuestionDetailEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            surveyQuestion: null,
            error: null,
            success: false,
            loading: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.setSurveyQuestionState = this.setSurveyQuestionState.bind(this);
    }

    componentDidMount() {
        if(this.props.surveyQuestion) {
            this.setSurveyQuestionState(this.props.surveyQuestion);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.surveyQuestion !== this.props.surveyQuestion) {
            this.setSurveyQuestionState(this.props.surveyQuestion);
        }
    }

    setSurveyQuestionState(state) {
        this.setState((prevState) => {
            return { surveyQuestion: { ...prevState.surveyQuestion, ...state } }
        })
    }

    onSubmit() {
        this.setState({ success: false });
        if(this.state.surveyQuestion.title.trim().length === 0) {
            this.setState({ error: "Naam is vereist." });
            return;
        }
        this.setState({ error: null, loading: true });
        axios.post("/setSurveyQuestion", {
            organizationId: this.props.organizationsContext.currentOrganization.id,
            surveyQuestionId: this.state.surveyQuestion.id,
            title: this.state.surveyQuestion.title,
            description: this.state.surveyQuestion.description
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ surveyQuestion: response.data.surveyQuestion, success: true });
                    this.props.setSurveyQuestion(response.data.surveyQuestion);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    render() {
        const { surveyQuestion, error, success, loading } = this.state;
        if(!surveyQuestion) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : success && (
                    <Alert variant="success">
                        Wijzigingen opgeslagen!
                    </Alert>
                )}
                <div className="mb-3">
                    <SurveyQuestionForm
                        surveyQuestion={ surveyQuestion }
                        setSurveyQuestion={ this.setSurveyQuestionState }
                        loading={ loading }
                    />
                </div>

                <Button variant="primary" className="float-right" onClick={ this.onSubmit } disabled={ loading }>
                    { loading && (
                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </React.Fragment>
        )
    }
}
SurveyQuestionDetailEdit.propTypes = {
    surveyQuestion: PropTypes.object,
    setSurveyQuestion: PropTypes.func.isRequired,
    organizationsContext: OrganizationsContextPropTypes
}

export default withOrganizationsContext(SurveyQuestionDetailEdit);
