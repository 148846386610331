import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/DetailOverviewRow";
import numberFormatter from "../../../components/NumberFormatter";

function ProjectDetailOverview({ project }) {
    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                        <DetailOverviewRow title="Naam">
                            { project ? project.name : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Seats">
                            { project ? numberFormatter({ number: project.seats }) : <Skeleton width={50}/> }
                        </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
ProjectDetailOverview.propTypes = {
    project: PropTypes.object
};

export default React.memo(ProjectDetailOverview);
