import React, {
    useMemo
} from "react";
import SimpleMDEEditor from "react-simplemde-editor";
import PropTypes from "prop-types";

import SurveyQuestionDescriptionMarkdown from "./SurveyQuestionDescriptionMarkdown";

function SurveyQuestionForm({ surveyQuestion, setSurveyQuestion, loading }) {
    const simpleMdeOptions = useMemo(() => {
        return {
            spellChecker: false,
            autoDownloadFontAwesome: false,
            toolbar: ["bold", "italic", "heading", "|", "quote", "unordered-list", "ordered-list", "|", "link", "horizontal-rule", "|", "guide"]
        }
    }, []);
    const onDescriptionChange = useMemo(() => {
        return (value) => {
            setSurveyQuestion({ description: value });
        }
    }, [setSurveyQuestion]);
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h4>Productinformatie</h4>
                    <div className="form-group mb-0">
                        <label htmlFor="name">Titel</label>
                        <input type="text" className="form-control" placeholder="Naam" id="name" required disabled={ loading }
                               value={surveyQuestion.title} onChange={ (event) => setSurveyQuestion({ title: event.target.value })}
                        />
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <h4>Beschrijving</h4>
                    <div className="row">
                        <div className="col-xl">
                            <SimpleMDEEditor
                                value={ surveyQuestion.description }
                                onChange={ onDescriptionChange }
                                options={ simpleMdeOptions }
                            />
                        </div>
                        <div className="col-md">
                            <h5>Resultaat</h5>
                            <SurveyQuestionDescriptionMarkdown>
                                { surveyQuestion.description }
                            </SurveyQuestionDescriptionMarkdown>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
SurveyQuestionForm.propTypes = {
    surveyQuestion: PropTypes.object,
    setSurveyQuestion: PropTypes.func,
    loading: PropTypes.bool
}

export default React.memo(SurveyQuestionForm);
