import React from "react";
import axios from "axios";
import {
    Redirect,
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import {
    CSSTransition
} from "react-transition-group";

import AuthenticatedUserContext from "./context/AuthenticatedUserContext";
import OrganizationsContext, {
    OrganizationsManager
} from "./context/OrganizationsContext";

import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import Organizations from "./pages/Organizations";
import BackofficeRouter from "./BackofficeRouter";
import Logo from "./components/Logo";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWhiteOverlayOverride: false,
            showInitialLoadingIndicator: false,
            authenticatedUser: {
                user: undefined,
                loginFunction: this.login.bind(this),
                logoutFunction: this.logout.bind(this)
            }
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.state.authenticatedUser.user === undefined) {
                this.setState({ showInitialLoadingIndicator: true });
            }
        }, 100);
        this.getSession();
    }

    setUser(user) {
        this.setState(previousState => {
            return { authenticatedUser: { ...previousState.authenticatedUser, user: user } }
        })
    }

    showWhiteOverlayOverridden(value, stateCheck = () => { return false }) {
        this.setState({ showWhiteOverlayOverride: value, showInitialLoadingIndicator: false });
        if(value) {
            setTimeout(() => {
                if(stateCheck()) {
                    this.setState({ showInitialLoadingIndicator: true });
                }
            }, 100);
        }
    }

    login(state, onErrorOccurred) {
        const email = state.email;
        const password = state.password;

        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user === null });
        axios.post("/login", { email: email, password: password, useCookie: true, deviceType: "desktop" })
            .then((response) => {
                if(response.data.valid) {
                    setTimeout(() => {
                        this.setUser(response.data.user);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        if(response.data.error === "INVALID_CREDENTIALS") {
                            onErrorOccurred("Foutieve gebruikersnaam of wachtwoord.");
                        } else {
                            onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                        }
                    }, 1000);
                }
            })
            .catch(() => {
                setTimeout(() => {
                    onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                }, 1000);
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 1000);
            });
    }

    getSession() {
        axios.get("/getSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setUser(response.data.user);
                } else {
                    this.setUser(null);
                }
            })
            .catch(() => {
                this.setUser(null);
            })
    }

    logout() {
        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user !== null });
        axios.get("/logout")
            .then((response) => {
                if(response.data.valid) {
                    setTimeout(() => {
                        this.setUser(null);
                    }, 500);
                } else {
                    // TODO: Display pop-over error message.
                }
            })
            .catch(() => {
                // TODO: Display pop-over error message.
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 500);
            });
    }

    render() {
        return (
            <Router>
                <OrganizationsManager>
                    <OrganizationsContext.Consumer>
                        { organizationContext =>
                            <React.Fragment>
                                <CSSTransition in={ this.state.authenticatedUser.user === undefined || this.state.showWhiteOverlayOverride || (this.state.authenticatedUser.user && organizationContext.organizations === null) }
                                               timeout={500} classNames="initial-load-background" unmountOnExit>
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        height: "100vh",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#F9FBFD",
                                        zIndex: 1100
                                    }}>
                                        <CSSTransition in={ this.state.showInitialLoadingIndicator } timeout={500} classNames="initial-load-logo" appear={true} mountOnEnter>
                                            {/*<AnimatedLogo color="#FF5000" animating={ true } className="initial-load-logo-enter"*/}
                                            {/*              style={{ height: "125px", width: "100%" }}/>*/}
                                            <Logo style={{ maxWidth: "600px" }}/>
                                        </CSSTransition>
                                    </div>
                                </CSSTransition>

                                { this.state.authenticatedUser.user !== undefined && (
                                    <AuthenticatedUserContext.Provider value={ this.state.authenticatedUser }>
                                        <Switch>

                                            <PrivateRoute authenticated={ this.state.authenticatedUser.user === null }
                                                          path="/login" target="/organizations"
                                                          component={ Login }
                                            />

                                            <PrivateRoute authenticated={ this.state.authenticatedUser.user !== null }
                                                          path="/" target="/login"
                                            >
                                                <Switch>
                                                    <Route path="/organizations" component={ Organizations }/>
                                                    <Route path="/organization/:organizationCodeName" component={ BackofficeRouter }/>
                                                    <Route path="/">
                                                        <Redirect to="/organizations"/>
                                                    </Route>
                                                </Switch>
                                            </PrivateRoute>

                                        </Switch>
                                    </AuthenticatedUserContext.Provider>
                                )}
                            </React.Fragment>
                        }
                    </OrganizationsContext.Consumer>
                </OrganizationsManager>
            </Router>
        );
    }
}

export default App;
