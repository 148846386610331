import React, {
    useEffect,
    useState
} from "react";
import {
    Chart
} from "react-charts";
import PropTypes from "prop-types";

function SurveyQuestionAnswersResponseChart({ answers }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        let responseAmounts = {};
        answers.forEach((answer) => {
            const response = answer.response;
            if(response in responseAmounts) {
                responseAmounts[response] += 1;
            } else {
                responseAmounts[response] = 1;
            }
        });
        let chartData = [];
        for(const [response, amount] of Object.entries(responseAmounts)) {
            chartData.push({
                label: response,
                data: [{
                    key: "Aantal",
                    value: amount
                }]
            });
        }
        setData(chartData);
    }, [answers]);

    const primaryAxis = React.useMemo(() => ({
        getValue: datum => datum.key,
    }), []);
    const secondaryAxes = React.useMemo(() => [
        {
            getValue: datum => datum.value,
            elementType: "bar",
            min: 0
        },
    ], []);

    if(!data) {
        return null;
    }

    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes
            }}
        />
    )
}
SurveyQuestionAnswersResponseChart.propTypes = {
    answers: PropTypes.array
}

export default React.memo(SurveyQuestionAnswersResponseChart);
