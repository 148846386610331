import React from "react";

import Title from "../../components/Title";

function Error404() {
    return (
        <Title pretitle="Error">404 Not Found</Title>
    )
}

export default React.memo(Error404);
