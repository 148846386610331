import React from "react";
import ReactMarkdown from "react-markdown";

function SurveyQuestionDescriptionMarkdown(props) {
    const { children, ...restProps } = props;
    return (
        <ReactMarkdown
            components={{
                // eslint-disable-next-line
                a: (linkProps) => {
                    return (
                        <a
                            href={ linkProps.href }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            { linkProps.children }
                        </a>
                    )
                }
            }}
            { ...restProps }
        >
            { children }
        </ReactMarkdown>
    )
}
SurveyQuestionDescriptionMarkdown.propTypes = ReactMarkdown.propTypes;

export default React.memo(SurveyQuestionDescriptionMarkdown);
