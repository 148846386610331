import React, {
    useContext,
    useMemo
} from "react";
import PropTypes from "prop-types";
import {
    Link
} from "react-router-dom";
import {
    Button
} from "react-bootstrap";

import OrganizationsContext from "../../../../context/OrganizationsContext";

function QuestionListItem({ question, survey, setNodeRef, style, listeners, attributes, isDragging = false }) {
    const organizationsContext = useContext(OrganizationsContext);

    const url = useMemo(() => {
        const currentOrganization = organizationsContext.currentOrganization;
        if(!question || !survey || !currentOrganization) {
            return null;
        }
        return `/organization/${currentOrganization.codeName}/survey/${survey.id}/question/${question.id}`;
    }, [question, survey, organizationsContext.currentOrganization]);

    return (
        <div
            className="card mb-2"
            style={{ ...style, opacity: isDragging ? 0.3 : 1 }}
            ref={ setNodeRef }
        >
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div style={{ opacity: 0.4 }}>
                        <Button
                            variant="light"
                            style={{ cursor: isDragging ? "grabbing" : "grab" }}
                            { ...listeners }
                            { ...attributes }
                        >
                            <i className="fas fa-bars fa-fw"/>
                        </Button>
                    </div>
                    <div className="text-muted text-right" style={{ minWidth: "40px" }}>
                        #{ question.id }
                    </div>
                    <div className="flex-grow-1 ml-3">
                        <h5 className="my-0">
                            { question.title }
                        </h5>
                    </div>
                    <div className="ml-3">
                        <Link to={ url } className="btn btn-primary btn-sm no-drag">
                            Vraag bewerken
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
QuestionListItem.propTypes = {
    question: PropTypes.object,
    survey: PropTypes.object,
    setNodeRef: PropTypes.func,
    style: PropTypes.object,
    listeners: PropTypes.object,
    attributes: PropTypes.object,
    isDragging: PropTypes.bool
}

export default React.memo(QuestionListItem);
