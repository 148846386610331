import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";

import Title from "../../components/Title";
import Loading from "../../components/Loading";
import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../context/OrganizationsContext";
import Helmet from "../../components/Helmet";

class Projects extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            projects: null,
            error: null
        }
    }

    componentDidMount() {
        this.getProjects();
    }

    getProjects() {
        const currentOrganization = this.props.organizationsContext.currentOrganization
        axios.post("/getProjects", { organizationId: currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ projects: response.data.projects });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data." });
            })
    }

    render() {
        const currentOrganization = this.props.organizationsContext.currentOrganization
        const { error, projects } = this.state;
        return (
            <React.Fragment>
                <Helmet title="Projecten"/>
                <Title pretitle="Overzicht" noBottom={ projects && projects.length > 0 }>
                    Projecten
                </Title>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : projects === null ? (
                    <Loading/>
                ) : projects.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-box-open"/></h1>
                        <h3>Geen projecten</h3>
                        <p>Er zijn geen projecten in deze organisatie.</p>
                    </div>
                ) : (
                    <Table hover size="sm" className="mt-4">
                        <thead className="thead-light">
                            <tr className="tr-sticky">
                                <th className="text-center">#</th>
                                <th>Naam</th>
                            </tr>
                        </thead>
                        <tbody>
                            { projects.map((project) => {
                                const url = `/organization/${currentOrganization.codeName}/project/${project.id}`;
                                return (
                                    <tr className="table-clickable-row" key={ project.id }>
                                        <td className="text-center">
                                            <Link to={ url }>
                                                { project.id }
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={ url }>
                                                { project.name }
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                )}
            </React.Fragment>
        )
    }

}
Projects.propTypes = {
    organizationsContext: OrganizationsContextPropTypes
}

export default withOrganizationsContext(Projects);
