import React, {
    useState
} from "react";
import {
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";

function ChartModal({ show, handleClose, title, children }) {
    const [showing, setShowing] = useState(false);
    return (
        <Modal size="fullscreen" show={ show } onHide={ handleClose } onShow={ () => setShowing(true) } onExited={ () => setShowing(false) }>
            <Modal.Header closeButton>
                <Modal.Title className="flex-grow-1">
                    <i className="fas fa-chart-bar ml-2 mr-3"/>
                    { title }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="h-100">
                    { showing && children }
                </div>
            </Modal.Body>
        </Modal>
    )
}
ChartModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element
    ])
}

export default React.memo(ChartModal);
