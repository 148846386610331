import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Alert,
    Button,
    Table
} from "react-bootstrap";

import {
    OrganizationsContextPropTypes,
    withOrganizationsContext
} from "../../../context/OrganizationsContext";
import Loading from "../../../components/Loading";
import DateFormatter from "../../../components/DateFormatter";
import NewSurveyInviteModal from "./modal/NewSurveyInviteModal";

class SurveyDetailInvites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            invites: null,
            error: null,

            showNewInviteModal: false
        }
    }

    componentDidMount() {
        if(this.props.survey) {
            this.getInvites();
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if(this.props.survey && this.props.survey !== prevProps.survey) {
            this.getInvites();
        }
    }

    getInvites() {
        const surveyId = this.props.survey.id;
        this.setState({ error: null });
        axios.post("/getSurveyInvites", { surveyId, organizationId: this.props.organizationsContext.currentOrganization.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ invites: response.data.invites });
                } else {
                    this.setState({ error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                this.setState({ error: "Er was een fout bij het ophalen van de data." });
            });
    }

    render() {
        const { invites, error } = this.state;
        if(error) {
            return (
                <Alert variant="danger">{ error }</Alert>
            )
        }
        if(!invites) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                <NewSurveyInviteModal
                    show={ this.state.showNewInviteModal }
                    handleClose={ () => this.setState({ showNewInviteModal: false }) }
                    survey={ this.props.survey }
                    onInviteAdded={ () => this.getInvites() }
                />
                { invites.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-box-open"/></h1>
                        <h3>Geen uitnodigingen</h3>
                        <p>Er zijn geen uitnodigingen voor deze survey.</p>
                        <Button variant="primary" className="mb-3" onClick={ () => this.setState({ showNewInviteModal: true }) }>
                            <i className="fas fa-plus mr-2"/>
                            Nieuwe uitnodiging
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        <div>
                            <Button variant="primary" className="mb-3" onClick={ () => this.setState({ showNewInviteModal: true }) }>
                                <i className="fas fa-plus mr-2"/>
                                Nieuwe uitnodiging
                            </Button>
                        </div>
                        <Table>
                            <thead className="thead-light">
                            <tr className="tr-sticky">
                                <th className="text-center">#</th>
                                <th>Datum aangemaakt</th>
                                <th className="text-center">Gebruikt</th>
                            </tr>
                            </thead>
                            <tbody>
                            { invites.map((invite) => {
                                return (
                                    <tr key={ invite.id }>
                                        <td className="text-center">
                                            { invite.id }
                                        </td>
                                        <td>
                                            <DateFormatter date={ invite.date }/>
                                        </td>
                                        <td className="text-center">
                                            { invite.used ? (
                                                <i className="fas fa-check text-success fa-fw"/>
                                            ) : (
                                                <i className="fas fa-times text-danger fa-fw"/>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

SurveyDetailInvites.propTypes = {
    survey: PropTypes.object,
    surveyId: PropTypes.number,
    organizationsContext: OrganizationsContextPropTypes
};

export default React.memo(withOrganizationsContext(SurveyDetailInvites));
