import React, {
    useContext
} from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import PropTypes from "prop-types";

import {
    SidebarManager
} from "@zandor300/backoffice-framework";
import OrganizationsContext from "./context/OrganizationsContext";
import Loading from "./components/Loading";
import BackofficeSidebar from "./components/layout/BackofficeSidebar";
import BackofficeNavbar from "./components/layout/BackofficeNavbar";
import BackofficePageContainer from "./components/layout/BackofficePageContainer";

import Error404 from "./pages/error/Error404";
import Dashboard from "./pages/dashboard/Dashboard";
import Projects from "./pages/projects/Projects";
import ProjectDetail from "./pages/projects/detail/ProjectDetail";
import Surveys from "./pages/surveys/Surveys";
import SurveyDetail from "./pages/surveys/detail/SurveyDetail";
import SurveyQuestionDetail from "./pages/surveys/survey-questions/detail/SurveyQuestionDetail";

function BackofficeRouter(props) {
    const organizationsContext = useContext(OrganizationsContext);
    if(organizationsContext.organizations === null || organizationsContext.currentOrganization === undefined) {
        console.log("Waiting for organizations to load...")
        return (
            <Loading/>
        )
    }
    if(organizationsContext.currentOrganization === null) {
        console.log("currentOrganization is null, return to organizations page.")
        return (
            <Redirect to="/organizations"/>
        )
    }

    return (
        <SidebarManager>
            <BackofficeNavbar/>
            <BackofficeSidebar/>

            <BackofficePageContainer>
                <Switch>
                    <Route path="/organization/:organizationCodeName/dashboard" component={Dashboard} />
                    <Route path="/organization/:organizationCodeName/projects" component={Projects} />
                    <Route path="/organization/:organizationCodeName/project/:projectId" component={ProjectDetail} />
                    <Route path="/organization/:organizationCodeName/surveys" component={Surveys} />
                    <Route path="/organization/:organizationCodeName/survey/:surveyId/question/:surveyQuestionId" component={SurveyQuestionDetail} />
                    <Route path="/organization/:organizationCodeName/survey/:surveyId" component={SurveyDetail} />

                    <Route path="/organization/:organizationCodeName" exact>
                        <Redirect
                            to={{
                                pathname: props.location.pathname.replace(/\/$/, "") + "/dashboard",
                                state: { from: "/" }
                            }}
                        />
                    </Route>

                    <Route path="/organization/:organizationCodeName" component={Error404}/>
                </Switch>
            </BackofficePageContainer>
        </SidebarManager>
    )
}
BackofficeRouter.propTypes = {
    location: PropTypes.object
}

export default BackofficeRouter;
